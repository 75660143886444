export default class NiceI18nTranslationsEn {
  constructor() {
    return {
      addresses: {
        prompt_to_delete: "Are you sure you want to delete this address?",
        delete: "Delete",
        cancel_destroy: "Cancel"
      },
      orders: {
        address: {
          select_address: "Select Address",
          text_select_region: '-- Select Province / Region --',
          text_select_country: '-- Select Country --'
        }
      },
      flashes: {
        line_item_max_quantity_reached: "The quantity you have selected is larger than the available one. Please select a smaller quantity",
        not_available_quantity_for_product: "Not available quantity for product"
      },
      activerecord: {
        errors: {
          models: {
            address: {
              attributes: {
                country: {
                  blank: "Please fill in your country"
                },
                region: {
                  blank: "Please fill in your region"
                },
                vat_number: {
                  invalid: {
                    requirements: "Please fill in a valid VAT number",
                    response: "Non valid VAT number or cross border transactions not activated"
                  }
                },
                vat_office_id: {
                  blank: "Please fill in the VAT office"
                },
                zip: {
                  blank: "Please fill in your zip code"
                }
              }
            },
            line_item: {
              attributes: {
                option: {
                  required: "This field is required.",
                  email_error: "Please enter a valid e-mail address"
                }
              }
            }
          }
        },
        models: {
          address: {
            attributes: {
              vat_number:{
                response: "VAT number is valid"
              }
            }
          }
        }
      },
      gift_lists: {
        edit_cart: "Edit Cart",
        delete: "Delete",
        cancel_destroy: "Cancel",
        prompt_to_delete: "Are you sure you want to delete this gift list?",
        items: {
          prompt_to_delete: "Are you use you want to remove this product from your gift list?"
        },
        url: {
          share_again: "Share Gift List Again",
          share: "Share Gift List"
        },
        show_giftlists: "Show gift lists",
        hide_giftlists: "Hide gift lists"
      },
      shared: {
        site_selector_popup: {
          choose_country: "CHOOSE COUNTRY",
          choose_language: "CHOOSE LANGUAGE"
        },
        header: {
          search: {
            text_search: "Search products...",
            no_data: "No results found...",
            all_results: "See all results",
            not_enough_letters: {
              part_1: "Please type in at least",
              part_2: "characters..."
            }
          }
        }
      },
      gdpr: {
        cookies: {
          popup: {
            update_preferences: "Update your Cookies Preferences"
          }
        }
      },
      pay_modal: {
        card: {
          card_holder: "Card Holder",
          expires: "Expires",
          mm: "MM",
          yy: "YY"
        },
        card_form: {
          card_number: "Card Number",
          card_name: "Card Name",
          expiration_date: "Expiration Date",
          month: "Month",
          year: "Year",
          cvv: "CVC",
          submit: "PAY NOW",
          payment_approved: "SUCCESSFUL PAYMENT",
          payment_not_approved: "PAYMENT DECLINED",
          message_for_declined_payment: "Unfortunately your card was declined with message:"
        },
        errors: {
          card: {
            genericError: "Please check again the fields you completed.",
            cardName: {
              blank: "The card name is required",
              minSize: {
                notmet: "Card name should be at least 2 character long"
              }
            },
            cardNumber: {
              blank: "Card number is required",
              creditCard: {
                invalid: "Card number is not valid"
              },
              minSize: {
                notmet: "The card number should be at least 13 number long"
              }
            },
            cardCvv: {
              blank: "CVC can't be blank",
              card: {
                cvc: {
                  invalid: "Not valid CVC"
                }
              }
            },
            cardMonth: {
              nullable: "Month is required"
            },
            cardYear: {
              nullable: "Year is required",
              expYear: {
                expired: "Your card has expired"
              }
            },
            transaction: "An error occured while processing your transaction: "
          }
        }
      },
      saved_orders: {
        saved_line_item: {
          already_exists: "Product was not added because it already exists"
        },
        saved_order: {
          no_line_items_on_save: "Save was unsuccessful. The order has zero products.",
          add_to_cart_prompt: "Only saved changes will be applied to cart. Do you want to proceed?",
          prompt_to_delete: "Are you sure you want to delete this order?",
          default_title: "Without title"
        },
        product_select: {
          placeholder: "Search products...",
          no_products_found: "No products were found...",
          empty_list: "List is empty...",
          selected_label: "",
          deselected_label: "Deselect",
          limit_text: {
            and: "and"
          }
        }
      }
    }
  }
}