<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../lib/store'

import Utils from '../lib/utils'
import AjaxState from '../lib/ajax_state'

import NiceI18n from '../lib/nice_i18n'
import niceModal from '../mixins/nice_modal'
import objectMixin from '../mixins/object_mixin'
import { getCustomPrice } from '../api/api'

import GiftLists from './gift_lists/GiftLists.vue'
import CustomOption from './products/CustomOption.vue'
import GiftCardValue from './products/GiftCardValue.vue'

import queryString from 'query-string'
import HoverIntent from 'vue2-hover-intent'
import { debounce, get } from 'lodash'

import MultiSelect from 'vue-multiselect'
import NotifyMeWhenBackInStock from '../components/notify_me_when_back_in_stock.vue'
import ProductStoreAvailability from '../components/product_store_availability.vue'
import OptionVariantsTable from './option_variants/OptionVariantsTable.vue'

export default Vue.component('product', {
  directives: { 'hover-intent': HoverIntent },
  components: {
    MultiSelect,
    NotifyMeWhenBackInStock,
    CustomOption,
    GiftCardValue,
    GiftLists,
    OptionVariantsTable
  },
  mixins: [niceModal, objectMixin],
  props: {
    product: {
      default: () => {
        return undefined
      },
      type: Object
    },
    productId: {
      type: [String, Number],
      default: ""
    },
    versions: {
      type: String,
      default: ""
    },
    load: {
      type: String,
      default: undefined
    },
    colorId: {
      type: String,
      default: undefined
    },
    wizardlessOptions: {
      default: () => [],
      type: Array
    },
    showSkuOnlyAttributeIfSkus: {
      type: String,
      default: "false"
    },
    giftListId: {
      type: Number,
      default: undefined
    },
    preselectOptionWithOneElement: {
      type: Boolean,
      default: false,
      note: "Preselect option if only One Element is not disabled"
    },
    customOptionsRequired: {
      type: Boolean,
      default: false
    },
    lineItemAttributes: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  store,
  data() {
    return {
      options: [],
      customOptions: [],
      skus: {},
      stocks: [],
      images: [],
      errors: {},
      productObject: {},
      selected: {},
      selectedObjects: {},
      customSelected: {},
      customSelectedObjects: {},
      addedToCart: false,
      clickedWhenDisabled: false,
      canBuy: true,
      optionVariantsTableFilled: false,
      quantity: 1,
      maxQuantity: 1,
      liveMaxQuantity: 1,
      currentPhotos: [],
      hasAvailableSizes: false,
      loadOptionsCalled: false,
      step: 1,
      unlimited_policy: false,
      quantityStep: 0,
      vatRate: 0.0
    }
  },
  computed: {
    quantities() {
      // Creates an range array from 1...this.maxQuantity, with steps based on
      // this.quantityStep. If minimum purchase quantity is 10 it will create
      // options based on steps of 10.
      const step   = (this.quantityStep > 0) ? this.quantityStep : 1
      const length = Math.floor(this.maxQuantity / step)
      return Array(length).fill().map((_, i) => (i + 1) * step)
    },
    alreadyAddedToCart() {
      return this.quantityAddedToCart > 0
    },
    quantityAddedToCart() {
      let quantity = 0

      for (let line_item of this.productLineItems) {
        quantity += line_item.quantity
      }

      return quantity
    },
    quantityWithCart() {
      return this.quantity + this.quantityAddedToCart
    },
    quantitySelectedAddedToCart() {
      let quantitySelected = null

      if (!this.selectedKey || this.selectedKey.length == 0 || this.checkObjectForSameValues(this.selectedKey, null) ||
          !this.order || !this.order.line_items || this.order.line_items.length < 1) {
        return 0
      }

      for (let key in this.selectedSkus) {
        let quantitySku = 0
        for (let { quantity, options } of this.order.line_items) {
          let lineOptions = this.objectValuesToArrayBasedOnArray(options, this.optionIds)
          if (lineOptions && key === lineOptions.join(',')) {
            quantitySku = quantity
          }
        }
        quantitySelected = quantitySelected === null ? quantitySku : quantitySelected
        quantitySelected = Math.min(quantitySelected, quantitySku)
      }
      return quantitySelected || 0
    },
    totalPriceAddedToCart() {
      if (this.productLineItems.length > 0) {
        return this.productLineItems[0].total_discounted_price
      } else {
        return null
      }
    },
    formattedStocks() {
      let stocks = []
      for (let [key, quantity] of Object.entries(this.stocks)) {
        let integer_key = []
        key.split(',').forEach((x) => {
          if (Number.isInteger(+x))
            integer_key.push(+x)
        })
        if (integer_key.length > 0){
          if (this.order && this.order.line_items && this.order.line_items.length > 0) {
            for (let lineItem of this.order.line_items) {
              if (lineItem.options && Utils.deepEqual(this.objectValuesToArrayBasedOnArray(lineItem.options, this.optionIds), integer_key)) {
                quantity = quantity - lineItem.quantity
              }
            }
          }
          stocks = [...stocks, { key: integer_key, quantity }]
        }
      }
      return stocks
    },
    productLineItems() {
      let lineItems = []

      if (!this.order || !this.order.line_items || this.order.line_items.length < 1) {
        return []
      }

      for (let lineItem of this.order.line_items) {
        if (lineItem.product_id == parseInt(this.computedProductId) && (this.options.length === 0 ||
        (lineItem.options && (lineItem.options.length === 0 || Utils.deepEqual(lineItem.options, this.selected))))) {
          lineItems.push(lineItem)
        }
      }

      return lineItems
    },
    wizardDisplay() {
      return (this.wizardlessOptions && this.wizardlessOptions.length > 0)
    },
    canAddToCart() {
      // check cart
      if (!this.loadOptionsCalled)
        return false

      if (this.maxQuantity === 0){
        return false
      }

      let can = this.canBuy

      if (this.wizardDisplay) {
        can = this.optionVariantsTableFilled
      } else {
        for (let option of this.options) {
          if (!this.selected[option.id]) {
            can = false
            break
          }
        }

        if (this.customOptionsRequired && this.customOptions.length > 0 && !Object.values(this.customSelected)[0]) {
          can = false
        }
      }

      return can
    },
    computedProductId() {
      if (this.product != undefined) {
        // If we are in wishlist, then get product id from `product_id` of wishlist line item.
        return (this.product.product_id != undefined) ? this.product.product_id : this.product.id
      }
      else {
        return this.productId
      }
    },
    optionIds() {
      return this.options.map(option => {
        return option.id
      })
    },
    colorOption() {
      for (let option of this.options) {
        if (option.is_color) {
          return option
        }
      }
      return null
    },
    colors() {
      let colorOption = this.colorOption
      return colorOption ? colorOption.option_variants : []
    },
    sizeOption() {
      for (let option of this.options) {
        if (option.is_size) {
          return option
        }
      }
      return null
    },
    sizes() {
      if (this.colorOption) {
        var selectedOptionVariant = this.detect(this.colorOption.option_variants, this.colorId)
        this.setSelected(selectedOptionVariant)
      }

      let sizeOption = this.sizeOption
      return sizeOption ? sizeOption.option_variants : []
    },
    availableSizes() {
      var sizes = this.sizes.map(option => {
        if (!option.disabled) {
          return option
        } else {
          return false
        }
      }).filter(Boolean)

      return sizes
    },
    selectedKey() {
      return this.objectValuesToArrayBasedOnArray(this.selected, this.optionIds)
    },
    availability() {
      return this.fetchFromSkuOrProduct("availability")
    },
    availabilityTitle() {
      return this.availability && (this.availability.title || this.availability.name)
    },
    selectedSkus() {
      let selectedSkus = {}
      for (let [id, sku] of Object.entries(this.skus)) {
        let idArray = id.split(",").map(Number)
        if (!this.checkObjectForSameValues(this.selectedKey, null) && this.matchArraysWithNulls(this.selectedKey, idArray)) {
          selectedSkus = { ...selectedSkus, [id]: sku }
        }
      }
      return selectedSkus
    },
    sku() {
      if (this.skus && !Utils.checkForEmptyObject(this.skus) && this.selectedKey)
        return this.skus[this.selectedKey.toString()]  || {}
      return {}
    },
    quantityDiscountedPrice() {
      let finalPrice = {}

      if (this.quantityDiscounts) {
        for (let _quantityDiscount of this.quantityDiscounts) {
          if ((this.quantity + this.quantityAddedToCart) >= _quantityDiscount.quantity) {
            finalPrice = _quantityDiscount

            break
          }
        }
      }

      return finalPrice
    },
    quantityDiscounts() {
      return this.fetchFromSkuOrProduct("quantity_discounts")
    },
    selectedWithCustom() {
      return { ...this.customSelected, ...this.selected }
    },
    customOptionsAreSelected() {
      return !Utils.checkForEmptyObject(this.customSelectedObjects) && !this.checkObjectForSameValues(this.customSelectedObjects, null)
    },
    barcode() {
      return this.fetchFromSkuOrProduct("barcode")
    },
    offered() {
      return this.fetchPriceFromSkuOrProduct("offered")
    },
    price() {
      return this.fetchPriceFromSkuOrProduct("price")
    },
    priceWithVat() {
      let price = Number(this.fetchPriceFromSkuOrProduct("raw_price"))

      if (isNaN(price))
        return

      if (this.config.prices_with_vat_excluded) {
        price += price * (this.vatRate / 100)
      }

      return price
    },
    discountedPrice() {
      return this.fetchPriceFromSkuOrProduct("discounted_price")
    },
    discountedPriceWithVat() {
      let discountedPrice = Number(this.fetchPriceFromSkuOrProduct("raw_discounted_price"))

      if (isNaN(discountedPrice))
        return

      if (this.config.prices_with_vat_excluded) {
        discountedPrice += discountedPrice * (this.vatRate / 100)
      }

      return discountedPrice
    },
    discountedPriceWithoutVat() {
      return this.fetchPriceFromSkuOrProduct("discounted_price_without_vat")
    },
    discountPercentage() {
      return this.fetchPriceFromSkuOrProduct("discount_percentage")
    },
    savings() {
      return this.fetchPriceFromSkuOrProduct("savings")
    },
    wizardOptions() {
      var options = this.options.map(option => {
        if (this.wizardlessOptions.indexOf(option.handle) > -1) {
          return false
        } else {
          return option
        }
      }).filter(Boolean)

      return options
    },
    nonWizardOptions() {
      // Difference - See https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
      return this.options.filter(x => !this.wizardOptions.includes(x))
    },
    nonWizardOption() {
      return (this.nonWizardOptions || [])[0]
    },
    nonWizardOptionVariants() {
      let optionVariants = (this.nonWizardOption || {}).option_variants

      if (optionVariants) {
        optionVariants.sort((a, b) => {
          return a.title - b.title
        })
      }

      return optionVariants
    },
    unavailableOptions() {
      var keys = Object.keys(this.options).map(e => parseInt(e))

      if (keys.length < 1) return {}

      var unavailableOptions = {}

      for (var key of keys) {
        var option = this.options[key]

        var optionVariants = option.option_variants.map(optionVariant => {
          if (optionVariant.disabled) {
            return optionVariant
          } else {
            return false
          }
        }).filter(Boolean)

        unavailableOptions[option.id] = optionVariants
      }

      return unavailableOptions
    },
    projectAgoraAdId() {
      let productAds = JSON.parse(window.localStorage.getItem('sponsoredProductAds'))

      if (productAds === null) {
        return undefined
      }
      else {
        return productAds[this.productId]
      }
    },
    ...mapState([
      'order',
      'history',
      'promotions',
      'recentlyAdded'
    ]),
    ...mapGetters([
      'apiPath',
      'signedIn'
    ])
  },
  watch: {
    selected: {
      handler: function(val, oldVal) {
        this.refreshAvailabilities()
      },
      deep: true
    },
    formattedStocks: {
      handler: function(val, oldVal) {
        this.refreshAvailabilities()
      },
      deep: true
    },
    totalPriceAddedToCart: function(val, oldVal) {
      if (val !== oldVal){
        this.refreshAvailabilities()
      }
    },
    selectedObjects: {
      handler: function(newValue, oldValue) {
        Object.entries(newValue).forEach(([key, value]) => {
          this.selected = { ...this.selected, [key]: value && value.id || "" }
        })
      },
      deep: true
    },
    customSelectedObjects: {
      handler: function(newValue, oldValue) {
        Object.entries(newValue).forEach(([key, value]) => {
          this.customSelected = { ...this.customSelected, [key]: value && value.id || "" }
        })
      },
      deep: true
    },
    quantityWithCart: function(newValue, oldValue) {
      if (newValue != oldValue && typeof newValue === "number")
        this.fetchCustomPrice()
    },
    selectedWithCustom: {
      handler: function(newValue, oldValue) {
        this.fetchCustomPrice()
      },
      deep: true
    },
    availableSizes: {
      handler: function(val) {
        if (this.loadOptionsCalled)
          this.hasAvailableSizes = val.length > 0
      },
      deep: true
    },
    quantity: function(val) {
      this.refreshQuantity()
    },
    maxQuantity: function(val) {
      this.refreshQuantity()
    }
  },
  mounted() {
    if (this.load != undefined)
      this.loadOptions()

    this.bindRangeSliderEventListener()

    this.skuOnlyAttributeIfSkus = this.showSkuOnlyAttributeIfSkus == "true"
  },
  methods: {
    /*
     * Fetches from API the options, option variants & photos of specific product.
     * This method is called automagically based on `load` prop that is passed in this Vue.js app.
     */
    loadOptions(rangeSlider, $event) {
      this.$store.dispatch('triggerEvent', { type: 'gy::options-start-loading', event: $event })

      let state = AjaxState.initialize(`${this._uid}-productLoadOptions`)
      if (state.fired) return

      // If we are in bonus product of a given promotion we want to first remove existing bonus product
      // from cart before selecting again options and adding it again to cart. So in this case:
      // a) it's enabled remove it from cart
      // or b) return directly if its not enabled
      if (this.promotion != undefined) {
        if (this.enabled) {
          this.removeBonusProductFromCart()
        }
        else {
          return false
        }
      }

      this.justAddedToCart = false


      state.fire()

      // Get option_variant_id from URL to be used for color preselection
      this.params = queryString.parse(queryString.extract(window.location.href))

      let versions = this.versions ? this.versions : undefined

      return this.$http.get(`${this.apiPath}/products/${this.computedProductId}/options`,
        {
          params: {
            versions: versions,
            range_slider: rangeSlider
          }
        }
      ).then(response => {
        this.config                             = response.body.config || {}
        this.vatRate                            = response.body.vat_rate
        this.unlimited_policy                   = response.body.unlimited_policy || false
        this.quantityStep                       = response.body.quantity_step
        this.options                            = response.body.options
        this.customOptions                      = response.body.custom_options
        this.stocks                             = response.body.stocks
        this.images                             = response.body.images
        this.skus                               = response.body.skus
        this.productObject.availability         = response.body.availability
        this.productObject.prices               = response.body.prices || {}
        this.productObject.offered              = this.productObject.prices.offered

        this.productObject.price                = this.productObject.prices.price
        this.productObject.raw_price            = this.productObject.prices.raw_price
        this.productObject.discounted_price     = this.productObject.prices.discounted_price
        this.productObject.raw_discounted_price = this.productObject.prices.raw_discounted_price
        this.productObject.discount_percentage  = this.productObject.prices.discount_percentage
        this.productObject.savings              = this.productObject.prices.savings
        this.productObject.quantity_discounts   = response.body.quantity_discounts

        if (response.body.can_buy != undefined)
          this.canBuy  = response.body.can_buy

        this.options.forEach((option) => {
          this.$set(this.selected, String(option.id), null)
        })

        // Below line serves backwards compatibility needs due to API change
        let customOptions = Array.isArray(this.customOptions) ? this.customOptions : Object.values(this.customOptions)

        customOptions.forEach((option) => {
          this.$set(this.customSelected, String(option.id), "")
        })

        this.refreshAvailabilities()

        // Set default quantity as minimum purchase quantity if applicable
        if (this.quantityStep > 0)
          this.quantity = this.quantityStep

        // Emit event for successful loading
        this.loadOptionsCalled = true
        this.$store.dispatch('triggerEvent', { type: 'gy::options-loaded', item: this })
        state.clear()
      })
    },

    /*
     * Loads prices from API for given options of this product.
     * This method is called automagically based on `load` prop that is passed in this Vue.js app.
     */
    loadPrices() {},

    /*
     * Sets selected option variant to passed value.
     * This method is used when we use non select elements, for example custom ul/li elements with anchor tags.
     * @param {Object} optionVariant - the option variant to set as the selected one.
     */
    setSelected(optionVariant) {
      if (optionVariant) {
        this.selected[optionVariant.option_id] = optionVariant.id
      }
    },

    /*
     * Returns true if the given optionVariant is actually selected.
     * This method can be used to add a special class for the selected option variants.
     * @param {Object} optionVariant - the option variant to check if it's selected.
     * @return {boolean} - returns true if option variant is selected.
     */
    isSelected(optionVariant) {
      if (optionVariant) {
        return this.selected[optionVariant.option_id] == optionVariant.id
      }
    },

    /*
     * Clears errors hash
     */
    clearErrors() {
      this.errors = {}
    },

    /*
     * Boolean (true/false) that returns if we have an error in a given option.
     * @param {Object} option - the option we want to check if has error.
     */
    hasError(option) {
      return this.errors[option.id] != null
    },

    /*
     * Boolean (true/false) that returns if we have any error.
     */
    hasErrors() {
      return Object.keys(this.errors).length > 0
    },

    /*
     * Check if all options are valid, and return true/false if not valid.
     */
    optionsValidated() {
      if (this.wizardDisplay) {
        return true
      } else {

        let idx = 0

        for (let optionId of Object.keys(this.selected)) {
          let optionVariantId = this.selected[optionId]
          let isRegularOption = this.options.map((o) => o.id).includes(Number(optionId))

          if (isRegularOption && (!optionVariantId || !Number(optionVariantId)) > 0) {
            this.$set(this.errors, optionId, this.options[idx])
          }
          else {
            delete this.errors[optionId]
          }

          idx++
        }

        return !this.hasErrors()
      }
    },

    /*
     * Trigger event gy::photos-changed only once
     */
    triggerPhotosChanged: debounce(function() {
      this.$store.dispatch('triggerEvent', 'gy::photos-changed')
    }, 5),

    /*
     * Trigger event gy::option-variant-changed only once
     */
    triggerOptionVariantChanged: debounce(function() {
      this.$store.dispatch('triggerEvent', 'gy::option-variant-changed')
    }, 15),

    /*
     * Returns the photos of given product.
     * Takes _optional_ parameter the option_variant_id (color_id)
     * @param {Integer} option_variant_id - the option_variant_id, default is null
     * @return {Array.<Object>} - returns an array of photos
     */
    photos(option_variant_id = null) {
      let requested_variant = option_variant_id
      let photos = this.currentPhotos

      if (option_variant_id == null) {
        for (let option of this.options) {
          if (option.has_images) {
            for (let optionVariant of option.option_variants) {
              if (this.selected[option.id] == optionVariant.id) {
                option_variant_id = optionVariant.id
                break
              }
            }
          }
        }
      }

      if (option_variant_id || this.colorOption == null) {
        for (let color of this.images) {
          if (color.option_variant_id == option_variant_id) {
            // Memorize result to not trigger again the event if photos don't need to change
            if ((!requested_variant && this.option_variant_id != option_variant_id) || this.colors.length == 0) {
              this.triggerPhotosChanged()
              this.option_variant_id = option_variant_id
              this.currentPhotos = color['photos']
            }
            photos = color['photos']
          }
        }
      }
      if (photos.length > 0) {
        return photos
      }
      else if (this.images[0] && this.images[0]['photos']) {
        // If we still don't have photos to show for the incoming color, fallback
        // to the first photos available
        return this.images[0]['photos']
      }
    },

    /*
     * Returns the specific version of given photo .
     * @param {Object} photo - the photo, which must be the result of photos() method.
     * @param {String} version - the version that you wish to display. Versions are passed as props during the creation of app.
     * @return {String} - returns the URL of specific photo
     */
    photoUrl(photo, version = null) {
      if (version) {
        return photo[version]
      } else {
        let key = Object.keys(photo)[0]
        return photo[key]
      }
    },

    fetchCustomPrice() {
      if (this.customOptionsAreSelected || (!Utils.checkForEmptyObject(this.sku) && this.config.sku_prices_api_only)) {
        getCustomPrice(this.apiPath, this.computedProductId, this.selectedWithCustom, this.quantityWithCart)
          .then((response)=>{
            let finalPrice = get(response, "data.price")

            if (!Utils.checkForEmptyObject(finalPrice)) {
              let priceObj = Utils.checkForEmptyObject(this.sku) ? this.productObject : this.sku

              Vue.set(priceObj, 'offered', finalPrice.offered)
              Vue.set(priceObj, 'price', finalPrice.price)
              Vue.set(priceObj, 'raw_price', finalPrice.raw_price)
              Vue.set(priceObj, 'discounted_price', finalPrice.discounted_price)
              Vue.set(priceObj, 'raw_discounted_price', finalPrice.raw_discounted_price)
              Vue.set(priceObj, 'discount_percentage', finalPrice.discount_percentage)
              Vue.set(priceObj, 'savings', finalPrice.savings)
            }
          })
      }
    },

    /*
     * Matches two arrays based on if they have the same elements. The difference is that it returns true also if
     * some (or all) of the elements is null, undefined or missing.
     * @param {Array} array1 - the first array to match
     * @param {Array} array2 - the second array to match
     * @return {Boolean} - returns true/false
     */
    matchArraysWithNulls(array1, array2) {
      if (array1.length === undefined || array2.length === undefined)
        return false
      for (let i = 0; i < array1.length; i++) {
        if (array1[i] != array2[i] & array1[i] != null & array2[i] != null) {
          return false
        }
      }
      return true
    },

    /*
     * Calculates AvailabilityStatus from availability from the api.
     * @param {Object} availability
     * @return {Number} availabilityStatus -
     *   0: Unavailable, 1: Upon request, 2: Available
     */
    calculateAvailabilityStatus(availability) {
      if (!availability) {
        return 2
      }
      if (availability.upon_order) {
        return 1
      }
      else if (availability.available) {
        return 2
      }
      else {
        return 0
      }
    },

    /*
     * Calculates max availability in skus based on a key. Disable if key not in skus
     * @param {Object} skus
     * @param {String} key - stringified array
     * @return {Number} availability -
     *   null: Check productObject, 0: Unavailable, 1: Upon request, 2: Available
     * disabled -
     *   undefined: exist in skus, true: not exists
     */
    calculateAvailabilityInSkus(key, skus) {
      let availabilityStatus = 0
      let disabled = true

      for (let [id, sku] of Object.entries(skus)) {
        let idArray = id.split(",").map(Number)
        let availability = sku.availability

        if (this.matchArraysWithNulls(key, idArray)) {
          disabled = undefined
          availabilityStatus = Math.max(
            availabilityStatus,
            this.calculateAvailabilityStatus(availability)
          )
        }
      }

      return [availabilityStatus, disabled]
    },

    /*
     * Formats the option variant array (color, size etc) based on the available stock.
     * Used to disable/enable options when not available.
     */
    formatOptionVariant(optionVariant, key_to_compare, maxOptionsQuantity) {
      let disabled
      [optionVariant.availabilityStatus, disabled] = this.calculateAvailabilityInSkus(key_to_compare, this.skus)
      optionVariant.maxQuantity = null
      optionVariant.quantity = null

      if (optionVariant.availabilityStatus === 0 || disabled === true) {
        optionVariant.disabled = optionVariant.$isDisabled = true
        return optionVariant, maxOptionsQuantity
      }

      for (let { quantity, key } of this.formattedStocks) {
        if (this.matchArraysWithNulls(key_to_compare, key)) {
          disabled = false
          optionVariant.maxQuantity = Math.max(quantity, optionVariant.maxQuantity)
          if (this.matchArraysWithNulls(key_to_compare, this.selectedKey)) {
            optionVariant.quantity = Math.max(quantity, optionVariant.quantity)  // backward compatibility
            maxOptionsQuantity = Math.max(quantity, maxOptionsQuantity)
          }
        }
      }

      if (disabled === undefined || optionVariant.maxQuantity === 0){
        disabled = !this.unlimited_policy
      }

      if (optionVariant.hasImages === true || optionVariant.availabilityStatus === null) {
        disabled = false
      }

      optionVariant.disabled = optionVariant.$isDisabled = disabled

      return optionVariant, maxOptionsQuantity
    },

    /*
     * Calculates the combinations between option variants (color, size etc) based on the available stock.
     * If there are no options, maxQuantity is just the default stock returned by the api
     * minus the quantity already added to the cart
     */
    refreshAvailabilities() {
      let maxOptionsQuantity = 0
      let index              = 0

      this.clearErrors()

      let key_to_compare

      if (this.options.length == 0) {
        this.maxQuantity = this.liveMaxQuantity = Math.max(0, (this.stocks.default || 0) - this.quantityAddedToCart)
      } else {
        this.options.forEach((option, index) => {
          let notDisabledOV = []

          for (let optionVariant of option.option_variants) {
            key_to_compare = this.selectedKey.slice(0)

            // For each option that we haven't yet selected
            if (key_to_compare[index] == null || key_to_compare[index] != optionVariant.id) {
              key_to_compare[index] = optionVariant.id
            }

            optionVariant.hasImages = option.has_images
            optionVariant, maxOptionsQuantity = this.formatOptionVariant(optionVariant, key_to_compare, maxOptionsQuantity)
            if (this.preselectOptionWithOneElement && optionVariant.disabled === false) {
              notDisabledOV.push(optionVariant)
            }
          }

          // Preselect first option variant if we have only one available
          if (notDisabledOV.length === 1 && this.selectedObjects[option.id] !== notDisabledOV[0])
            this.selectedObjects = this.setObjectEntry(this.selectedObjects, option.id, notDisabledOV[0])

          // Preselect first available option variant if we have set Option#preselect_first_option_variant as true
          if (this.selectedObjects[option.id] === undefined && option.preselect_first_option_variant && notDisabledOV.length > 0)
            this.selectedObjects = this.setObjectEntry(this.selectedObjects, option.id, notDisabledOV[0])
        })

        this.maxQuantity = this.unlimited_policy ?
          Math.max(0, this.stocks.default - this.quantitySelectedAddedToCart) :
          maxOptionsQuantity
        this.liveMaxQuantity = maxOptionsQuantity
      }

      // Preselect option variants
      this.selectOptionVariants()

      setTimeout(() => {
        this.triggerOptionVariantChanged()
      }, 50)
    },

    /*
     * Preselects first options available.
     */
    selectOptionVariants(index = 0) {
      if (this.options.length <= index) return

      let option = this.options[index]
      let selected = this.selected[option.id]

      // If the default option_variant is set for the current option, set is as pre-selected
      // TODO Currently, this functionality only works with `<select>` and `<option>` html markup for option variants. We should continue the implementation for `<ul>` and `<li>` markup (e.g. Access Fashion)
      if (option.default_option_variant_id && typeof selected != "undefined" && selected == null) {
        for (var optionVariant of option.option_variants) {
          if (optionVariant.id == option.default_option_variant_id) {
            for (var optionVariant of option.option_variants) {
              if (optionVariant.id == option.default_option_variant_id) {
                selected = option.default_option_variant_id
              }
            }
          }
        }
      }

      // If we display all colors in catalogue (given colorId), preselect product catalog item color,
      // but only the first time, not every time we try to select a different color
      if (!this.loadOptionsCalled) {
        for (var optionVariant of option.option_variants) {
          if (optionVariant.id == this.colorId){
            selected = optionVariant.id
            break
          }
        }
      }

      // If we don't have selected anything for this option, select one below
      if (selected == null) {
        if (option.has_images && this.params && this.params.option_variant_id) {
          for (var optionVariant of option.option_variants) {
            if (parseInt(this.params.option_variant_id) == optionVariant.id) {
              selected = optionVariant.id
              break
            }
          }
        }
      }

      // Check if existing selected option is available. If its not reset my selection
      if (selected > 0) {
        var ov = this.detect(option.option_variants, selected)
        if (ov && ov.disabled) {
          selected = null
        }
      }

      // Again only for sizes, preselect the first empty option if nothing found above
      if (!option.has_images && selected == null) {
        selected = ""
      }

      // If we have a color, preselect the first option available from option variants
      if (option.has_images && selected == null) {
        selected = (option.option_variants.find(option => !option.disabled) || {}).id
      }

      // Change this.selected if it actually changed to prevent infinite loops from watcher
      if (this.selected[option.id] != selected){
        this.selected[option.id] = selected
      }

      this.selectOptionVariants(index + 1)
    },

    /*
     * Adds to cart the given product, passing also the selected options & quantity if available.
     */
    addToCart($event) {
      // check cart
      if (this.maxQuantity === 0){
        this.$store.dispatch('triggerEvent', { type: 'gy::cannot-add-to-cart', message: NiceI18n.t("flashes.not_available_quantity_for_product"), item: this })
        return
      }

      let data = { products: [] }

      if (this.wizardDisplay && this.$refs.optionVariantsTable) {
        const optionVariantsTable = [].concat(this.$refs.optionVariantsTable)[0]

        // We have wizard type display, with table for multiple quantities in the end
        for (let optionVariantRow of optionVariantsTable.$refs.optionVariantRow) {
          if (optionVariantRow.quantity > 0 && optionVariantRow.shouldAddProductToCart) {
            data["products"].push({
              product_id: this.computedProductId,
              options: optionVariantRow.selected,
              quantity: optionVariantRow.quantity,
              promotion_id: (this.promotion || {}).id
            })
          }
        }
      } else {
        // We have only 1 product to be added in cart

        if (typeof this.projectAgoraAdId !== 'undefined') {
          this.lineItemAttributes.project_agora_ad_id = this.projectAgoraAdId
        }

        data["products"].push({
          product_id: this.computedProductId,
          options: this.selectedWithCustom,
          quantity: this.quantity,
          promotion_id: (this.promotion || {}).id,
          gift_list_id: this.giftListId,
          line_item_attributes: this.lineItemAttributes
        })
      }

      if (this.optionsValidated()) {
        this.$http.post(`${this.apiPath}/order`, data).then(response => {
          let $this = this
          $this.addedToCart = true

          let  { message, error, recently_added } = response.body
          this.$store.state.recentlyAdded = recently_added
          this.$store.dispatch('loadOrder')

          let optionVariantId = this.selected[(this.colorOption || {}).id]

          let triggerEventObj = {
            type: 'gy::added-to-cart',
            message,
            error,
            event: $event,
            optionVariantId
          }

          if (Array.isArray(recently_added) && recently_added.length > 0)
            triggerEventObj.item = recently_added[0]

          if (this.promotion) {
            this.$store.dispatch('triggerEvent', { type: 'gy::promotion-added-to-cart', message: response.body.message, event: $event })
          } else if (this.order.eligible_bonus_products !== undefined && this.order.eligible_bonus_products !== null) {
            this.$store.dispatch('triggerEvent', { type: 'gy::promotion-activated', triggerEventObj, event: $event })
          } else {
            this.$store.dispatch('triggerEvent', triggerEventObj)
          }

          setTimeout(() => {
            $this.addedToCart = false
          }, 4200)
        },
        (error) => {
          let message              = error.body.message
          let redirectToCartButton = { title: NiceI18n.t("gift_lists.edit_cart"), handler: () => { window.location.href = location.protocol + '//' + location.host + "/order"; } }
          let okButton             = { default: true, title: 'ΟΚ' }

          this.showModal(message, [redirectToCartButton, okButton])
        })
      } else {
        let $this = this
        $this.clickedWhenDisabled = true
        let errorMessages = []

        for (let key of Object.keys(this.errors)) {
          var option = this.errors[key]
          errorMessages.push(option.title)
        }

        this.$store.dispatch('triggerEvent', { type: 'gy::cannot-add-to-cart', message: errorMessages.join(', '), item: this })

        setTimeout(() => {
          $this.clickedWhenDisabled = false
        }, 800)
      }
    },

    /*
     * Adds to wishlist the given product, passing also the selected options & quantity if available.
     * Depending on if the user is logged in, than a 401 not authorized may be returned, where the
     * gy::user-needs-login event is triggered.
     *
     * Also if the user has already the selected item in wishlist, gy::already-exists-in-wishlist event
     * is triggered and the product is not added again to wishlist.
     */
    addToWishlist() {
      this.$http.post(`${this.apiPath}/wishlist_items`, {
        product_id: this.computedProductId,
        options: this.selected,
        quantity: this.quantity
      }).then(response => {
        this.$store.dispatch('loadWishlistItems')
        this.$store.dispatch('triggerEvent', { type: 'gy::added-to-wishlist', message: response.body.message })
      }, response => {
        // Not authorized
        if (response.status == 401) {
          this.$store.dispatch('setHistory', { component: this, method: 'addToWishList' })
          this.$store.dispatch('triggerEvent', { type: 'gy::user-needs-login', message: response.body.error })
        } else if (response.status == 406) {
          this.$store.dispatch('loadWishlistItems')
          this.$store.dispatch('triggerEvent', { type: 'gy::already-exists-in-wishlist', message: response.body.message })
        }
      })
    },

    /*
     * It deletes the product from the wishlist.
     * @param {Object} product - the line product we wish to delete
     * @event - gy::wishlist-product-deleted is triggered
     */
    deleteWishlistItem() {
      this.$http.delete(`${this.apiPath}/wishlist_items/${this.product.id}`).then(response => {
        this.$store.dispatch('loadWishlistItems')
        this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-item-deleted', message: response.body.message })
      })
    },

    /*
     * It adds selected product to cart.
     * @param {Object} product - the line product we wish to add to cart
     * @event - gy::wishlist-added-to-cart is triggered
     */
    addWishlistItemToCart() {
      this.$http.post(`${this.apiPath}/wishlist_items/add_to_cart`, { product_id: this.product.product_id, options: this.product.options }).then(response => {
        this.$store.dispatch('loadOrder')
        this.$store.dispatch('triggerEvent', { type: 'gy::wishlist-added-to-cart', message: response.body.message })
      })
    },

    /*
     * Increases the quantity of the product by 1
     * Used in a -/+ input field for increase/decrease operations
     * @event - gy::line-item-max-quantity-reached is triggered
     */
    refreshQuantity() {
      this.quantity = parseInt(this.quantity) || 1

      if (this.maxQuantity === 0) {
        this.quantity = 0
      }
      else if (this.quantity < 1) {
        this.quantity = 1
      }
      else if (this.quantity > this.maxQuantity) {
        setTimeout( () => {
          this.quantity = this.maxQuantity
          this.$store.dispatch('triggerEvent', { type: 'gy::line-item-max-quantity-reached', message: NiceI18n.t("flashes.line_item_max_quantity_reached") })
        }, 120)
      }
    },

    /*
     * Increases the quantity of the product by 1
     * Used in a -/+ input field for increase/decrease operations
     */
    increaseQuantity() {
      if (this.maxQuantity === 0) {
        this.$store.dispatch('triggerEvent', { type: 'gy::line-item-max-quantity-reached', message: NiceI18n.t("flashes.line_item_max_quantity_reached") })
      }
      this.quantity = parseInt(this.quantity) + 1
    },

    /*
     * Decreases the quantity of the product by 1
     * Used in a -/+ input field for increase/decrease operations
     */
    decreaseQuantity() {
      this.quantity = parseInt(this.quantity) - 1
    },

    /*
     * Takes an array of numbers, and returns the maximum one.
     * @param {Array} array - For example [1, 6, 7]
     * @return {Number} - the maximum number, for example 7 for above example
     */
    arrayMax(array) {
      return array.reduce((a, b) => {
        return Math.max(a, b)
      })
    },

    /*
     * Implement ruby like detect method. Takes a collecton as param and
     * speficic id and returns the first element of collection with id that matches
     * @param {Array} collection - For example [object, object, object]
     * @param {Number} id - For example 3
     */
    detect(collection, id) {
      return collection.filter((el) =>
        el.id == id
      )[0]
    },

    fetchFromSkuOrProduct(attribute) {
      let value = null

      if (!Utils.checkForEmptyObject(this.sku)) {
        value = this.sku[attribute]

      } else if (Utils.checkForEmptyObject(this.skus) || !this.skuOnlyAttributeIfSkus) {
        value = this.productObject[attribute]
      }

      return value
    },

    fetchPriceFromSkuOrProduct(attribute) {
      return Utils.checkForEmptyObject(this.quantityDiscountedPrice) ? this.fetchFromSkuOrProduct(attribute) : this.quantityDiscountedPrice[attribute]
    },

	  /*
     * If the project has slick this method can be called before changing
     * option variant to remove slick and initialize it again after gy::option-variant-changed
     * is fired.
     * Set the divs that have slick like so: .product-thumbs{ "ref" => "any_name" }
     * Example case used on click listener: "@click" => "unslick(); setSelected(optionVariant)"
    */
    unslick() {
      $.each(this.$refs, function(name,val){
        if ($(val).hasClass('slick-initialized')) {
          $(val).slick('unslick');
        }
      })
    },

    selectedOptionVariantForOption(option) {
      let optionVariantId = this.selected[option.id]

      if (optionVariantId) {
        for (let optionVariant of option.option_variants) {
          if (parseInt(optionVariant.id) == parseInt(optionVariantId)) {
            return optionVariant
          }
        }
      }
    },

    isOpened(index) {
      return (index + 1 == this.step)
    },

    isEditable(index) {
      return (index + 1 < this.step)
    },

    canProceedNextStep(option) {
      return (this.selected[option.id] && String(this.selected[option.id]).length > 0)
    },

    proceedStep(option) {
      if (this.canProceedNextStep(option))
        this.step += 1
    },

    editStep(step) {
      this.step = step + 1

      if (this.step < this.wizardOptions.length) {
        this.optionVariantsTableFilled = false
        for (var i = step; i <= this.wizardOptions.length - 1; i++) {
          let option_id = this.wizardOptions[i].id
          this.selected[option_id] = ""
        }
      }
    },

    hasNextStep() {
      return (this.step + 1 <= this.wizardOptions.length)
    },

    sort(optionVariants) {
      return optionVariants.sort((a, b) => {
        return a.title - b.title
      })
    },

    /*
     * Binds range slider event listener, that load again the options after
     * each change.
     */
    bindRangeSliderEventListener() {
      document.addEventListener('gy::product-page-range-slider-changed', function (event) {
        this.loadOptions(event.detail)
      }.bind(this))
    }
  }
})
</script>

<style scoped src="vue-multiselect/dist/vue-multiselect.min.css"></style>
