<template>
  <a class="row search-result">
    <div class="col-sm-3 text-center">
      <div class="search-result__photo">
        <img
          :src="item.photo"
          alt=""
        >
      </div>
    </div>
    <div class="col-sm-9">
      <a :href="item.path">
        <p class="search-result__title">
          {{ item.title }}
        </p>
        <p class="search-result__code">
          {{ item.code }}
        </p>
      </a>

      <p class="search-result-price" v-if="!hidePrice">
        <del
          v-if="item.price != item.discounted_price"
          class="search-result-price__original"
        >
          {{ item.price }}
        </del>
        <strong class="search-result-price__discounted">
          {{ item.discounted_price }}
        </strong>
      </p>
    </div>
  </a>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState([
      'hidePriceForProductGroupIds'
    ]),
    hidePrice() {
      if (this.item.product_group_id === undefined)
        return false

      return this.hidePriceForProductGroupIds.indexOf(this.item.product_group_id) > -1
    }
  }
}
</script>

<style scoped>
</style>
