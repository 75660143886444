<script>
import Vue            from 'vue'
import { mapGetters } from 'vuex'
import ProductSelect  from './SavedOrderProductSelect.vue'
import NiceI18n       from '../../lib/nice_i18n'

export default Vue.component('saved-line-item', {
  components: {
    ProductSelect
  },
  props: {
    item: {
      default: () => {
        return {}
      },
      type: Object
    },
    lineItemIndex: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'apiPath'
    ])
  },
  watch: {
    'item.quantity': function(newQuantity, oldQuantity) {
      let newQt = parseInt(newQuantity)

      if (newQt && newQt > 0) {
        let $this = this

        clearTimeout(this.tmt)

        this.tmt = setTimeout(() => {
          let obj = {
            productId: this.item.product_id,
            isQuantityUpdate: true
          }

          if (this.item.sku) obj.skuId = this.item.sku.id

          $this.fetchAndEmitProductInfo(obj)
        }, 200)

      } else if (!isNaN(newQt)) {
        this.item.quantity = 1
      }
    },

    'item.is_checked': function(newValue, oldValue) {
      this.$emit('gy:saved-line-item-checked-status-changed')
    }
  },
  methods: {
    /*
    * Fetch line item object based on the product id
    * @param {obj} productId, skuId, isQuantityUpdate
    */
    fetchAndEmitProductInfo(obj) {
      if (this.productIsAlreadyAdded(obj) && this.item.quantity > 0) {
        this.$http.get(`${this.apiPath}/line_items/build`, {
          params: {
            product_id: obj.productId,
            sku_id: obj.skuId,
            quantity: this.item.quantity
          }
        }).then(response => {
          var lineItem = response.body.line_item

          lineItem.id = this.item.id
          lineItem.is_checked = this.item.is_checked

          this.$emit('gy:saved-line-item-product-fetched', lineItem)
        })
      }
    },

    /*
    * Returns false if the product already exists in the order
    * @param {obj} productId, skuId, isQuantityUpdate
    */
    productIsAlreadyAdded(obj) {
      if (obj.isQuantityUpdate) return true

      let sameProductIds = this.$parent.item.line_items.filter((line_item) => {
        return line_item.product_id == obj.productId && (!line_item.sku || (line_item.sku && line_item.sku.id == obj.skuId))
      })

      if (this.$parent.item.line_items.length > 1 && sameProductIds.length > 0) {
        this.$parent.item.line_items.shift()

        this.$store.dispatch('triggerEvent', {
          type: 'gy::saved-line-item-already-exists',
          message: NiceI18n.t('saved_orders.saved_line_item.already_exists')
        })

        return false
      }

      return true
    },

    /*
    * Emit saved line item delete event to parent
    */
    emitDelete() {
      this.$emit('gy:saved-line-item-deleted', this.item.id)
    },

    /*
    * Enforces quantity of a line item on blur event
    */
    enforceQuantity() {
      if (this.item.quantity == '') this.item.quantity = 1
    }
  },
  template: '#saved-line-item-template'
})
</script>
