<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import Product from '../product.vue'

export default {
  extends: Product,
  props: {
    product: {
      default: () => {
        return {}
      },
      type: Object
    },
    bonusSku: {
      default: () => {
        return {}
      },
      type: Object
    },
    promotion: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      chosen: false,
      chosenStatus: false
    }
  },
  computed: {
    enabled() {
      return !this.disabled
    },
    lineItemId() {
      return (this.lineItem || {}).id
    },
    disabled() {
      if (this.order.eligible_bonus_products == undefined) {
        return true
      }

      if (this.$parent.sumOfEligibleQuantities < 1 && !this.chosenStatus) {
        return true
      }

      return this.order.eligible_bonus_products.filter((el) =>
        el.product_id == this.product.id
      ).length == 0 && !this.chosenStatus
    },
    lineItem() {
      if (this.order.line_items == undefined) {
        return
      }

      return this.order.line_items.filter((li) =>
        li.bonus_product && li.product_id == this.product.id
      )[0]
    },
    formattedOptions() {
      if (!this.bonusSku) {
        return []
      }

      let obj = this.bonusSku.options_to_text
      let options = []
      
      Object.keys(obj).forEach(function(key) {
        let text = `${key}: ${obj[key]}`
        options.push(text)
      })

      return options
    },
    ...mapState([
      'order',
      'recentlyAdded'
    ]),
    ...mapGetters([
      'apiPath'
    ])
  },
  watch: {
    lineItemId: function(newValue, oldValue) {
      if (newValue != oldValue && newValue == undefined) {
        this.deselect()
      }
    }
  },
  mounted() {
    this.preselect()
  },
  methods: {
    choose() {
      if (this.enabled) {
        this.chosen = !this.chosen

        if (this.chosen) {
          this.chosenStatus = true
          this.$parent.$emit('reserve-quantity', 1)

          this.setSelectedFromBonusSku()
          this.addToCart()
        } else {
          this.removeBonusProductFromCart()
        }
      }
    },
    removeBonusProductFromCart() {
      if (this.lineItemId) {
        this.$http.delete(`${this.apiPath}/line_items/${this.lineItemId}`).then(response => {
          this.$store.dispatch('loadOrder')

          this.$parent.$emit('release-quantity', 1)

          setTimeout(() => {
            this.chosenStatus = false
          }, 200)

          this.$store.dispatch('triggerEvent', { type: 'gy::line-item-deleted', message: response.body.message, item: this.item })
        })
      }
    },
    deselect() {
      if (this.lineItemId == undefined && this.chosen) {
        this.chosen       = false
        this.chosenStatus = false

        this.$parent.$emit('release-quantity', 1)
      }
    },
    preselect() {
      var addedToCart = this.lineItem != undefined

      this.chosen       = addedToCart
      this.chosenStatus = addedToCart
    },
    setSelectedFromBonusSku() {
      if (Object.keys(this.bonusSku).length !== 0) {
        this.selected = this.bonusSku.options_to_ids
      }
    }
  }
}
</script>
